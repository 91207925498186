export default (contact) => {
  for (let field in contact) {
    if (contact[field] == "") {
      return {
        visible: true,
        color: "error",
        text: "All fields are required",
      };
    }
  }

  if (
    !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      contact.email
    )
  ) {
    return {
      visible: true,
      color: "error",
      text: "E-Mail must be a valid format",
    };
  }

  return { success: true };
};
