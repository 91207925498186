<template>
  <div>
    <v-card height="100vh" width="100vw" dark tile>
      <v-container>
        <v-card-title> Contact Us </v-card-title>
        <v-container>
          <v-form-base
            :schema="schema"
            :model="model"
            :col="12"
            :row="{ noGutters: false }"
          ></v-form-base>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleSave" :disabled="submitting">Send</v-btn>
        </v-card-actions>
        <v-row>
          <v-col align="center">
            <v-btn v-if="showSubscribeButton" @click="joinMailingList(contact)">
              Subscribe to our mailing list to receive updates
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  import VFormBase from "vuetify-form-base";
  import { mapActions, mapState } from "vuex";
  export default {
    components: {
      VFormBase,
    },
    data: () => ({
      submitting: false,
      id: "",
      model: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      schema: {
        name: {
          label: "Name",
          type: "text",
          col: 6,
        },
        email: {
          label: "E-Mail Address",
          type: "email",
          col: 6,
        },
        subject: {
          label: "Which service interests you?",
          type: "select",
          items: [
            "Ecuadorian Bullion",
            "Other Bullion",
            "Private Broker Service",
            "I Want To Sell",
            "Other Investment Opportunities",
          ],
        },
        message: {
          type: "textarea",
          label: "Give us some details about your requirements",
        },
      },
    }),
    computed: {
      ...mapState(["showSubscribeButton", "contact"]),
    },
    methods: {
      ...mapActions(["saveContact", "joinMailingList"]),
      handleSave() {
        this.submitting = true;
        this.saveContact(this.model).then((response) => {
          if (response === false) {
            this.submitting = false;
            return;
          }

          this.model = {
            name: "",
            email: "",
            subject: "",
            message: "",
          };
        });
      },
    },
  };
</script>
