<template>
  <div>
    <v-container v-if="valid">
      <v-row>
        <v-col align="center">
          <v-btn href="/api/get-contacts"> Download Contacts (ZIP) </v-btn>
        </v-col>
        <v-col align="center">
          <v-btn href="/api/get-mailing-list">
            Download Mailing List (CSV)
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <v-row justify="center">
        <v-col cols="6" align="right">
          <v-text-field label="Username" v-model="username"></v-text-field>
          <v-text-field
            :type="showPass ? 'text' : 'password'"
            label="Password"
            v-model="password"
            :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPass = !showPass"
          ></v-text-field>

          <v-btn @click="signIn" :disabled="signingIn">{{
            signingIn ? "..." : "Sign In"
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import wretch from "wretch";
  import handleResponse from "@/assets/handle-response";
  import { mapActions } from "vuex";

  export default {
    data: () => ({
      username: "",
      password: "",
      valid: false,
      showPass: false,
      signingIn: false,
    }),
    methods: {
      ...mapActions(["setSnack"]),
      signIn() {
        this.signingIn = true;
        wretch("/api/verify-admin")
          .post({
            username: this.username,
            password: this.password,
          })
          .json()
          .then(handleResponse)
          .then((response) => {
            if (!response) {
              this.setSnack({
                visible: true,
                color: "error",
                text: "Invalid Credentials",
              });
              this.signingIn = false;
              return;
            }

            this.valid = true;
          })
          .catch((error) => {
            console.error(error);
            this.setSnack({
              visible: true,
              color: "error",
              text: "Unexpected error occurred. Contact developer.",
            });
            this.signingIn = false;
          });
      },
    },
  };
</script>
