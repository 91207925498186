import Vue from "vue";
import VueRouter from "vue-router";
import Contact from "@/views/Contact";
import Content from "@/views/Content";
import Home from "@/views/Home";
import Admin from "@/views/Admin";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { visible: true, remote: true },
  },
  {
    path: "/",
    name: "Download PDF Catalog",
    meta: { visible: true, remote: false, external: "/catalogo-kaf.pdf" },
  },
  { path: "/admin", name: "Admin", component: Admin, meta: { visible: false } },
  {
    path: "/why-precious-metals",
    name: "Why Invest In Precious Metals",
    component: Content,
    meta: { visible: true, remote: true },
  },
  {
    path: "/ecuador-precious-metals",
    name: "Ecuador Precious Metals",
    component: Content,
    meta: { visible: true, remote: true },
  },
  {
    path: "/other-bullion-or-coins",
    name: "Other Bullion or Coins",
    component: Content,
    meta: { visible: true, remote: true },
  },
  {
    path: "/private-broker-service",
    name: "Private Broker Service",
    component: Content,
    meta: { visible: true, remote: true },
  },
  {
    path: "/i-want-to-sell",
    name: "I Want To Sell",
    component: Content,
    meta: { visible: true, remote: true },
  },
  {
    path: "/other-investment-opportunities",
    name: "Other Investment Opportunities",
    component: Content,
    meta: { visible: true, remote: true },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: { visible: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
