<template>
  <div>
    <v-img src="@/assets/images/banner.jpg"> </v-img>
    <v-card dark tile min-height="25vh">
      <v-card-title style="max-width: 750px; margin: 0 auto 0 auto">
        {{ page.title }}
      </v-card-title>
      <v-card-text style="max-width: 750px; margin: 0 auto 0 auto">
        <div v-html="html"></div>
      </v-card-text>
    </v-card>

    <v-card dark tile min-height="75vh">
      <v-container>
        <v-card-title> Subscribe </v-card-title>
        <v-card-subtitle>
          to our mailing list to receive updates via e-mail
        </v-card-subtitle>
        <v-card-text style="max-width:750px margin:0 auto 0 auto">
          <v-row>
            <v-col>
              <v-text-field label="Name" v-model="name"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="E-Mail" v-model="email"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="joinMailingList({ name, email })">Join</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import Markdown from "markdown-it";

import { mapState, mapActions } from "vuex";
export default {
  data: () => ({
    name: "",
    email: "",
  }),
  computed: {
    ...mapState(["page"]),
    html() {
      Markdown;
      const md = new Markdown({
        html: true,
        linkify: true,
        typographer: true,
      });
      md;
      const frameWidth = this.$vuetify.mdAndUp ? "560px" : "100%";
      const contents = this.page.contents.replace("<frame-width>", frameWidth);
      return md.render(contents);
    },
  },
  methods: { ...mapActions(["joinMailingList"]) },
};
</script>
