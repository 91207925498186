import Vue from "vue";
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VCheckbox,
  VSelect,
  VSpacer,
  VList,
  VToolbar,
  VToolbarTitle,
  VItemGroup,
  VListItem,
  VListItemIcon,
  VIcon,
  VListItemContent,
  VListItemTitle,
  VListItemGroup,
  VBtn,
  VCard,
  VTextarea,
  VFileInput,
  VDatePicker,
  VMenu,
  VTimePicker,
  VSwitch,
  VSlider
} from "vuetify/lib";

import {
  Ripple,
  Intersect,
  Touch,
  Resize,
  ClickOutside
} from "vuetify/lib/directives";

Vue.use(Vuetify, {
  components: {
    VRow,
    VCol,
    VTextField,
    VTooltip,
    VCheckbox,
    VSelect,
    VSpacer,
    VList,
    VToolbar,
    VToolbarTitle,
    VItemGroup,
    VListItem,
    VListItemIcon,
    VIcon,
    VListItemContent,
    VListItemTitle,
    VListItemGroup,
    VBtn,
    VCard,
    VTextarea,
    VFileInput,
    VDatePicker,
    VMenu,
    VTimePicker,
    VSwitch,
    VSlider
  },
  directives: { Ripple, Intersect, Touch, Resize, ClickOutside }
});

const vuetify = new Vuetify({});
import VuetifyConfirm from "vuetify-confirm";
Vue.use(VuetifyConfirm, { vuetify });

export default vuetify;
