import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import wretch from "wretch";
import handleResponse from "@/assets/handle-response";
import validateForms from "@/assets/validate-forms";

export default new Vuex.Store({
  state: {
    page: { title: "", contents: "" },
    snack: { visible: false, color: "", text: "" },
    showSubscribeButton: false,
    contact: {},
  },
  mutations: {},
  actions: {
    getContents({ state }, path) {
      wretch(`/api/get-contents/${path.replace("/", "")}`)
        .get()
        .json()
        .then(handleResponse)
        .then((contents) => (state.page = contents.page))
        .catch(console.error);
    },
    async joinMailingList({ state }, { name, email }) {
      const valid = validateForms({ name, email });
      if (!valid.success) {
        state.snack = valid;
        return;
      }

      wretch("/api/join-mailing-list")
        .post({ name, email })
        .res()
        .then(() => {
          state.snack = {
            visible: true,
            color: "success",
            text: "Thanks! You should receive a confirmation letter soon.",
          };
        })
        .catch(console.error);
    },
    async saveContact({ state, dispatch }, contact) {
      dispatch, state;

      const valid = validateForms(contact);
      if (!valid.success) {
        state.snack = valid;
        return false;
      }

      state.contact = contact;

      wretch("/api/save-contact")
        .post(contact)
        .res()
        .then(() => {
          state.snack = {
            visible: true,
            color: "success",
            text:
              "Thank you for contacting us. We will respond as soon as possible.",
          };
          state.showSubscribeButton = true;
        })
        .catch(console.error);
    },
    setSnack({ state }, snack) {
      state.snack = snack;
    },
  },
  modules: {},
});
