<template>
  <div>
    <v-card dark tile>
      <v-img :src="page.image" height="100%" min-height="100vh">
        <v-card-title style="max-width: 750px; margin: 0 auto 0 auto">
          {{ page.title }}
        </v-card-title>
        <v-card-text style="max-width: 750px; margin: 0 auto 0 auto">
          <div v-html="html"></div>
        </v-card-text>
      </v-img>
    </v-card>
  </div>
</template>

<script>
import Markdown from "markdown-it";

import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["page"]),
    html() {
      Markdown;
      const md = new Markdown({
        html: true,
        linkify: true,
        typographer: true,
      });
      md;
      const frameWidth = this.$vuetify.mdAndUp ? "560px" : "100%";
      const contents = this.page.contents.replace("<frame-width>", frameWidth);
      return md.render(contents);
    },
  },
};
</script>
